var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-card",
    { staticClass: "todo-box", attrs: { shadow: "never" } },
    [
      _c(
        "div",
        {
          staticClass: "portal-common-title",
          attrs: { slot: "header" },
          slot: "header",
        },
        [_c("span", [_vm._v(_vm._s(_vm.title))])]
      ),
      _c(
        "div",
        { staticClass: "todo-box-body" },
        [
          _c(
            "router-link",
            {
              staticClass: "item",
              attrs: { to: "/plugin/workflow/process/todo" },
            },
            [
              _c("i", { staticClass: "icon-ym icon-ym-flowTodo" }),
              _c("div", { staticClass: "text" }, [
                _c("p", { staticClass: "num" }, [
                  _vm._v(_vm._s(_vm.numberOne)),
                ]),
                _c("p", { staticClass: "name" }, [_vm._v("待办事宜")]),
              ]),
            ]
          ),
          _c(
            "router-link",
            {
              staticClass: "item",
              attrs: { to: "/plugin/workflow/process/my-done" },
            },
            [
              _c("i", { staticClass: "icon-ym icon-ym-flowEntrust" }),
              _c("div", { staticClass: "text" }, [
                _c("p", { staticClass: "num" }, [
                  _vm._v(_vm._s(_vm.numberTwo)),
                ]),
                _c("p", { staticClass: "name" }, [_vm._v("我的已办")]),
              ]),
            ]
          ),
          _c(
            "router-link",
            {
              staticClass: "item",
              attrs: { to: "/plugin/workflow/process/done" },
            },
            [
              _c("i", { staticClass: "icon-ym icon-ym-flowDone" }),
              _c("div", { staticClass: "text" }, [
                _c("p", { staticClass: "num" }, [
                  _vm._v(_vm._s(_vm.numberThree)),
                ]),
                _c("p", { staticClass: "name" }, [_vm._v("办结事宜")]),
              ]),
            ]
          ),
          _c(
            "router-link",
            {
              staticClass: "item",
              attrs: { to: "/plugin/workflow/process/copy" },
            },
            [
              _c("i", { staticClass: "icon-ym icon-ym-flowDone" }),
              _c("div", { staticClass: "text" }, [
                _c("p", { staticClass: "num" }, [
                  _vm._v(_vm._s(_vm.numberFour)),
                ]),
                _c("p", { staticClass: "name" }, [_vm._v("抄送事宜")]),
              ]),
            ]
          ),
          _c(
            "router-link",
            {
              staticClass: "item",
              attrs: { to: "/plugin/workflow/process/claim" },
            },
            [
              _c("i", { staticClass: "icon-ym icon-ym-flowDone" }),
              _c("div", { staticClass: "text" }, [
                _c("p", { staticClass: "num" }, [
                  _vm._v(_vm._s(_vm.numberFive)),
                ]),
                _c("p", { staticClass: "name" }, [_vm._v("待签事务")]),
              ]),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }