var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-card",
    { staticClass: "portal-todoList-box", attrs: { shadow: "never" } },
    [
      _c(
        "div",
        {
          staticClass: "portal-common-title",
          attrs: { slot: "header" },
          slot: "header",
        },
        [_c("span", [_vm._v(_vm._s(_vm.title))])]
      ),
      _c(
        "div",
        { staticClass: "portal-todoList-box-body" },
        [
          _c("div", { attrs: { id: "iCenter" } }),
          _vm.show
            ? [
                _c("div", { staticClass: "weatherBox" }, [
                  _c("div", { staticClass: "weatherTime" }, [
                    _c("span", { staticStyle: { "font-size": "20px" } }, [
                      _vm._v("南京市"),
                    ]),
                  ]),
                  _c("div", { staticClass: "temperature" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.data.now.temperature) +
                        "℃\n          "
                    ),
                  ]),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.data.now.text) +
                        "\n          "
                    ),
                  ]),
                ]),
              ]
            : _c("div", { staticClass: "portal-common-noData" }, [
                _c("img", {
                  staticClass: "noData-img",
                  attrs: {
                    alt: "",
                    src: require("@/assets/images/portal-nodata.png"),
                  },
                }),
                _c("p", { staticClass: "noData-txt" }, [_vm._v("暂无数据")]),
              ]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }