var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-scrollbar",
    { staticClass: "layout-area" },
    [
      _vm.layout.length
        ? [
            _c(
              "grid-layout",
              {
                attrs: {
                  layout: _vm.layout,
                  "row-height": 40,
                  "is-draggable": false,
                  "is-resizable": false,
                },
                on: {
                  "update:layout": function ($event) {
                    _vm.layout = $event
                  },
                },
              },
              _vm._l(_vm.layout, function (item) {
                return _c(
                  "grid-item",
                  {
                    key: item.i,
                    attrs: {
                      x: item.x,
                      y: item.y,
                      w: item.w,
                      h: item.h,
                      i: item.i,
                      static: "",
                    },
                  },
                  [
                    item.snowsKey === "todo"
                      ? _c("Todo", { attrs: { title: item.title } })
                      : _vm._e(),
                    item.snowsKey === "commonFunc"
                      ? _c("CommonFunc", {
                          attrs: { title: item.title, list: item.list },
                        })
                      : _vm._e(),
                    item.snowsKey === "todoList"
                      ? _c("TodoList", { attrs: { title: item.title } })
                      : _vm._e(),
                    item.snowsKey === "notice"
                      ? _c("HNotice", { attrs: { title: item.title } })
                      : _vm._e(),
                    item.snowsKey === "email"
                      ? _c("HEmail", { attrs: { title: item.title } })
                      : _vm._e(),
                    item.snowsKey === "dataBoard"
                      ? _c("DataBoard", {
                          attrs: { title: item.title, list: item.list },
                        })
                      : _vm._e(),
                    item.snowsKey === "barChart"
                      ? _c("HBarChart", {
                          attrs: {
                            title: item.title,
                            option: item.option,
                            dataType: item.dataType,
                            propsApi: item.propsApi,
                          },
                        })
                      : _vm._e(),
                    item.snowsKey === "annularChart"
                      ? _c("HAnnularChart", {
                          attrs: {
                            title: item.title,
                            option: item.option,
                            dataType: item.dataType,
                            propsApi: item.propsApi,
                          },
                        })
                      : _vm._e(),
                    item.snowsKey === "areaChart"
                      ? _c("HAreaChart", {
                          attrs: {
                            title: item.title,
                            option: item.option,
                            dataType: item.dataType,
                            propsApi: item.propsApi,
                          },
                        })
                      : _vm._e(),
                    item.snowsKey === "lineChart"
                      ? _c("HLineChart", {
                          attrs: {
                            title: item.title,
                            option: item.option,
                            dataType: item.dataType,
                            propsApi: item.propsApi,
                          },
                        })
                      : _vm._e(),
                    item.snowsKey === "pieChart"
                      ? _c("HPieChart", {
                          attrs: {
                            title: item.title,
                            option: item.option,
                            dataType: item.dataType,
                            propsApi: item.propsApi,
                          },
                        })
                      : _vm._e(),
                    item.snowsKey === "radarChart"
                      ? _c("HRadarChart", {
                          attrs: {
                            title: item.title,
                            option: item.option,
                            dataType: item.dataType,
                            propsApi: item.propsApi,
                          },
                        })
                      : _vm._e(),
                    _vm.mask ? _c("div", { staticClass: "mask" }) : _vm._e(),
                  ],
                  1
                )
              }),
              1
            ),
          ]
        : _c("div", { staticClass: "portal-layout-nodata" }, [
            _c("img", {
              staticClass: "layout-nodata-img",
              attrs: {
                src: require("@/assets/images/dashboard-nodata.png"),
                alt: "",
              },
            }),
            _c("p", { staticClass: "layout-nodata-txt" }, [_vm._v("暂无数据")]),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }