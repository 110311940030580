var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-card",
    { staticClass: "portal-todoList-box", attrs: { shadow: "never" } },
    [
      _c(
        "div",
        {
          staticClass: "portal-common-title",
          attrs: { slot: "header" },
          slot: "header",
        },
        [_c("span", [_vm._v(_vm._s(_vm.title))])]
      ),
      _c(
        "div",
        { staticClass: "portal-todoList-box-body" },
        [
          _vm.list.length
            ? _vm._l(_vm.list, function (item, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticClass: "item com-hover",
                    on: {
                      click: function ($event) {
                        return _vm.readInfo(item.id)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(item.title)),
                    ]),
                    _c("span", { staticClass: "time" }, [
                      _vm._v(_vm._s(item.releaseTime)),
                    ]),
                  ]
                )
              })
            : _c("div", { staticClass: "portal-common-noData" }, [
                _c("img", {
                  staticClass: "noData-img",
                  attrs: {
                    src: require("@/assets/images/portal-nodata.png"),
                    alt: "",
                  },
                }),
                _c("p", { staticClass: "noData-txt" }, [_vm._v("暂无数据")]),
              ]),
        ],
        2
      ),
      _vm.formVisible ? _c("Form", { ref: "Form" }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }