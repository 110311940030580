var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-card",
    { staticClass: "portal-todoList-box", attrs: { shadow: "never" } },
    [
      _c(
        "div",
        {
          staticClass: "portal-common-title",
          attrs: { slot: "header" },
          slot: "header",
        },
        [_c("span", [_vm._v(_vm._s(_vm.title))])]
      ),
      _c(
        "div",
        { staticClass: "portal-todoList-box-body" },
        [
          _vm.list.length
            ? _vm._l(_vm.todoList, function (item, i) {
                return _c(
                  "a",
                  {
                    key: i,
                    staticClass: "item com-hover",
                    on: {
                      click: function ($event) {
                        return _vm.goDetail(item)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(item.processDefinitionName)),
                    ]),
                    _c("span", { staticClass: "time" }, [
                      _vm._v(
                        _vm._s(_vm._f("toDate")(item.createTime, "yyyy-MM-dd"))
                      ),
                    ]),
                  ]
                )
              })
            : _c("div", { staticClass: "portal-common-noData" }, [
                _c("img", {
                  staticClass: "noData-img",
                  attrs: {
                    src: require("@/assets/images/portal-nodata.png"),
                    alt: "",
                  },
                }),
                _c("p", { staticClass: "noData-txt" }, [_vm._v("暂无数据")]),
              ]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }