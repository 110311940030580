var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "SNOWS-dialog SNOWS-dialog_center SNOWS-dialog-notice",
      attrs: {
        title: "查看消息",
        "close-on-click-modal": false,
        visible: _vm.visible,
        "lock-scroll": "",
        width: "80%",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "notice-wrapper",
        },
        [
          _c("h1", { staticClass: "title" }, [_vm._v(_vm._s(_vm.info.title))]),
          _c("div", { staticClass: "info" }, [
            _c("span", [
              _vm._v(_vm._s(_vm._f("toDate")(_vm.info.releaseTime))),
            ]),
            _c("span", [_vm._v(_vm._s(_vm.info.releaseUser))]),
          ]),
          _c("div", {
            staticClass: "main",
            domProps: { innerHTML: _vm._s(_vm.info.content) },
          }),
          _vm.files.length
            ? _c(
                "div",
                { staticClass: "file-list" },
                [
                  _c("SNOWS-UploadFz", {
                    attrs: { disabled: "", detailed: "" },
                    model: {
                      value: _vm.files,
                      callback: function ($$v) {
                        _vm.files = $$v
                      },
                      expression: "files",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }