var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-card",
    { staticClass: "commonFunc-box", attrs: { shadow: "never" } },
    [
      _c(
        "div",
        {
          staticClass: "portal-common-title",
          attrs: { slot: "header" },
          slot: "header",
        },
        [_c("span", [_vm._v(_vm._s(_vm.title))])]
      ),
      _c(
        "div",
        { staticClass: "commonFunc-box-body" },
        _vm._l(_vm.menuList, function (item, i) {
          return _c(
            "app-link",
            { key: i, staticClass: "item com-hover", attrs: { to: item.path } },
            [
              _c("i", {
                class: item.icon,
                style: { color: item.iconBackgroundColor || "#1890FF" },
              }),
              _c("p", { staticClass: "name" }, [_vm._v(_vm._s(item.fullName))]),
            ]
          )
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }