<template>
  <el-card class="portal-todoList-box" shadow="never">
    <div slot="header" class="portal-common-title">
      <span>{{ title }}</span>
    </div>
    <div class="portal-todoList-box-body">
      <div id="iCenter"></div>
      <template v-if="show">
        <div class="weatherBox">
          <div class="weatherTime">
<!--            <span>更新时间：{{ sinoma.getDate() }}</span>-->
            <span style="font-size: 20px">南京市</span>
          </div>
          <div class="temperature">
            {{data.now.temperature}}℃
          </div>
          <div class="text">
            {{data.now.text}}
          </div>
        </div>
      </template>
      <div class="portal-common-noData" v-else>
        <img alt="" class="noData-img" src="@/assets/images/portal-nodata.png">
        <p class="noData-txt">暂无数据</p>
      </div>
    </div>
  </el-card>
</template>
<script>
import {getTodayWeather} from "@/api/onlineDev/portal";
export default {
  props: {
    title: {type: String, default: ''}
  },
  data() {
    return {
      list: [],
      data: {},
      show:false
    }
  },
  mounted() {
    // this.getLocation();
    this.getWeather()
  },
  methods: {
    // getLocation() {
    //   AMap.plugin("AMap.Geolocation", () => {
    //     var geolocation = new AMap.Geolocation({
    //       // 是否使用高精度定位，默认：true
    //       enableHighAccuracy: true,
    //       // 设置定位超时时间，默认：无穷大
    //       timeout: 10000,
    //     });
    //     geolocation.getCityInfo((status, result) => {   //只能获取当前用户所在城市和城市的经纬度
    //       console.log(status,'55')
    //       if (status == "complete") {
    //         // let city = result.city.replace("市","");
    //         // console.log(city,'58')
    //         // var location = pinyin(city,{'style':'NORMAL'}).join('')
    //         this.getWeather()
    //       }
    //     })
    //     geolocation.getCurrentPosition((status, result) => {  //获取用户当前的精确位置
    //       if (status == "complete") {
    //         console.log(result, '57')
    //       }
    //     })
    //   })
    // },
    getWeather() {
      getTodayWeather('nanjing').then((res) => {
        if (res.data.code == 200) {
          this.show = true;
          this.data = res.data.data.results[0];
        }
      })
    }
  }
}
</script>
<style scoped>
.el-card::v-deep .el-card__body {
  background-image: url("~@/assets/images/weather.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.el-card::v-deep .weatherBox {
  color: #FFFFFF;
}
.weatherTime {
  width: 100%;
  display: flex;
  justify-content: space-between;
  line-height: 30px;
}
.temperature {
  width: 100%;
  text-align: center;
  font-size: 60px;
  margin: 20px 0 0;
}
.text {
  width: 100%;
  text-align: center;
  font-size: 35px;
}
</style>
