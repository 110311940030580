var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    {
      staticClass: "dataBoard",
      attrs: { gutter: 10, type: "flex", justify: "space-between" },
    },
    _vm._l(_vm.realList, function (item, i) {
      return _c(
        "el-col",
        { key: i, staticClass: "dataBoard-item" },
        [
          _c("el-card", { attrs: { shadow: "never" } }, [
            _c("div", { staticClass: "dataBoard-body" }, [
              _c("i", {
                class:
                  item.icon +
                  " dataBoard-body-item dataBoard-body-item" +
                  (i + 1),
              }),
              _c("div", { staticClass: "text" }, [
                _c("p", { staticClass: "num" }, [_vm._v(_vm._s(item.num))]),
                _c("p", { staticClass: "name" }, [
                  _vm._v(_vm._s(item.fullName)),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }